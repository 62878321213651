import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/seo';

import StoreLocator from '../screens/locator';

const IndexPage = ({ data }) => {
  const { stores } = data;
  const finalStores = stores.nodes.map((s) => ({
    ...s,
    lat: parseFloat(s.lat),
    lng: parseFloat(s.lng)
  }));
  return (
    <>
      <Seo />
      <StoreLocator
        stores={finalStores}
      />
    </>
  );
};

export const query = graphql`
  query {
    stores: allGoogleSpreadsheetStores {
      nodes {
        id,
        street: adresse
        postalCode: codePostale
        address: fullAddress
        lat: latitude
        lng: longitude
        name: nom
        phone: tel
        town: ville
        campaign: campagne
        # rtf
      }
    }
  }
`;

export default IndexPage;
