import React from 'react';

import Title from '../../components/title';

import { Icon } from '../../components/icons';
import Close from '../../icons/Close.svg';
// import Email from '../../icons/Email.svg';
// import Fraise from '../../icons/Fraise.svg';
import Phone from '../../icons/Phone.svg';

import trads from './trads';

import { Wrapper } from './PoiCard.styles';
import { useLocale } from '../../services/i18n/useLocale';

const MarkerPoiCard = (props) => {
  const intl = useLocale();

  return (
    <Wrapper className={props.className}>
      <Title anim={false} title={props.name} className="bbox white" />
      {!props.preview ? (
        <button className="clear close" onClick={props.onClose}>
          <Icon src={Close} width={12} />
        </button>
      ) : null}
      <div className="infos">
        {!props.preview ? (
          <>
            <address>
              {props.street}
              <br />
              {props.postalCode} {props.town}
            </address>
            {/* <div className="line">
              <div className="icon-container">
                <Icon
                  src={Email}
                  width={15}
                />
              </div>
              <div className="text-container">
                <a href={`mailto:${props.email}`}>
                  {props.email}
                </a>
              </div>
            </div> */}

            <div className="line">
              <div className="icon-container">
                <Icon src={Phone} width={15} />
              </div>
              <div className="text-container">
                <a href={`tel:${props.phone}`}>{props.phone}</a>
              </div>
            </div>

            {props.campaign === '1' ? (
              <div className="line">
                <div className="text-container">
                  <span className="com">Participe à l’opération Jackpot !</span>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <p>{intl.formatMessage(trads.poiInfos)}</p>
        )}
      </div>
    </Wrapper>
  );
};

export default React.memo(MarkerPoiCard);
